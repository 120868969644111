import React from 'react'

function Section02() {

  return (
    <div
      className='w-full  bg-[#D0C5AF]'
    >

        <div className=' relative  flex flex-col md:flex-row items-center md:px-20 md:py-20 gap-4 '>
          <div className='md:w-1/2'>
            <img src={process.env.PUBLIC_URL+'/images/section02_p01.png'} alt="" className='max-w-full    ' data-aos="fade" data-aos-duration="1000"/>
          </div>
          <div className=' md:w-1/2 px-10 pb-10 md:px-24'>
            <img src={process.env.PUBLIC_URL+'/images/section02_title.png'} alt="" className='max-w-full   ' />
          </div>
        </div>

    </div>
  )
}

export default Section02