import React from 'react'

function Section06() {

  return (
    <div
      className='w-full  bg-[#D0C5AF]'
    >

        <div className=' relative  flex items-end pr-0 py-20 gap-3  md:gap-10 '>
          <div className='w-2/3'>
            <img src={process.env.PUBLIC_URL+'/images/section06_p01.png'} alt="" className='max-w-full    ' data-aos="fade" data-aos-duration="1000"/>
          </div>
          <div className=' w-1/3 flex flex-col justify-between pr-4  '>
            <div className='px-2 md:px-12 h-full'>
              <img src={process.env.PUBLIC_URL+'/images/section06_title.png'} alt="" className='max-w-full   ' />

            </div>
            <div className='mt-4 md:mt-10  '>
              <img src={process.env.PUBLIC_URL+'/images/section06_p02.png'} alt="" className='max-w-full ' data-aos="fade" data-aos-duration="1000"/>

            </div>

          </div>
        </div>


    </div>
  )
}

export default Section06