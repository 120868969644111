import React from 'react'

function Section04() {

  return (
    <div
      className='w-full  bg-[#D0C5AF] bg-cover bg-no-repeat bg-top relative'
    >
      <img src={process.env.PUBLIC_URL+'/images/section04_bg.png'} alt=""  className='w-full object-cover' data-aos="fade" data-aos-duration="1000"/>
      <div className=' absolute top-[8%] left-[8%]  w-[40%]'>
        <img src={process.env.PUBLIC_URL+'/images/section04_title.png'} alt="" className='max-w-full' data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000"/>
      </div>



    </div>
  )
}

export default Section04