import React from 'react'

function Section07() {

  return (
    <div
      className='w-full  bg-[#D0C5AF] bg-cover bg-no-repeat bg-top relative'
    >
      <img src={process.env.PUBLIC_URL+'/images/section07_bg.png'} alt=""  className='w-full object-cover' data-aos="fade" data-aos-duration="1000"/>
      <div className=' absolute top-[20%] left-[8%]  w-[40%]'>
        <img src={process.env.PUBLIC_URL+'/images/section07_title.png'} alt="" className='max-w-full' data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000"/>
      </div>

      <div className='flex items-center gap-3 md:gap-10 pt-10 pb-6 md:pb-16'>
        <div className='w-2/3'>
          <img src={process.env.PUBLIC_URL+'/images/section07_p01.png'} alt="" className='max-w-full' data-aos="fade" data-aos-delay="200" data-aos-duration="1000"/>
        </div>
        <div className='pr-4 md:pr-14 md:pl-10'>
          <img src={process.env.PUBLIC_URL+'/images/section07_text.png'} alt="" className='max-w-full' data-aos="fade" data-aos-delay="200" data-aos-duration="1000"/>
        </div>
      </div>



    </div>
  )
}

export default Section07