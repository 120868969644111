import React from 'react'

function Section10() {

  return (
    <div className='w-full'>


      <div className='bg-[#7F000C]  w-full p-4 flex  justify-around items-center'>
        <a className='p-1' tel="0225586888">
          <img src={process.env.PUBLIC_URL+'/images/tel.png'} alt="" className='max-w-full'/>
        </a>
        <a className='p-1'  href="https://www.facebook.com/profile.php?id=100070381843106" target='_blank'>
          <img src={process.env.PUBLIC_URL+'/images/fb.png'} alt="" className='max-w-full'/>
        </a>
        <a className='p-1' href="https://maps.app.goo.gl/n2cWNhJaMHbUKrns8" target='_blank'>
          <img src={process.env.PUBLIC_URL+'/images/map.png'} alt="" className='max-w-full'/>
        </a>
       
      </div>
    </div>
  )
}

export default Section10