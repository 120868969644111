import React from 'react'

function Section05() {

  return (
    <div
      className='w-full  bg-[#D0C5AF]'
    >

        <div className=' relative  flex items-end pr-0 py-20 gap-4 h-full'>
          <div className='w-1/2'>
            <img src={process.env.PUBLIC_URL+'/images/section05_p01.png'} alt="" className='max-w-full    ' data-aos="fade" data-aos-duration="1000"/>
          </div>
          <div className=' w-1/2 flex flex-col justify-between h-full '>
            <div className='px-4 md:px-24 h-full'>
              <img src={process.env.PUBLIC_URL+'/images/section05_title.png'} alt="" className='max-w-full   ' />

            </div>
            <div className='mt-5 md:mt-20  '>
              <img src={process.env.PUBLIC_URL+'/images/section05_p02.png'} alt="" className='max-w-full ' data-aos="fade" data-aos-duration="1000"/>

            </div>

          </div>
        </div>
        <div className='flex items-center'>
          <div className='w-1/2 px-4 md:px-24'>
            <img src={process.env.PUBLIC_URL+'/images/section05_title2.png'} alt="" className='max-w-full    ' data-aos="fade" data-aos-duration="1000"/>
          </div>
          <div className='w-1/2'>
            <img src={process.env.PUBLIC_URL+'/images/section05_p03.png'} alt="" className='max-w-full    ' data-aos="fade" data-aos-duration="1000"/>
          </div>
        </div>

    </div>
  )
}

export default Section05