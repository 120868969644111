import React from 'react'
function Header() {
  return (
    <div className=' relative' >
      <img src={process.env.PUBLIC_URL+'/images/header_bg.png'} alt=""  className='w-full object-cover' data-aos="fade" data-aos-duration="1000"/>
      <div className=' absolute top-[18%] left-[20%]  w-1/3'>
        <img src={process.env.PUBLIC_URL+'/images/header_logo.png'} alt="" className='max-w-full' data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000"/>
      </div>

      <div className='bg-[#7F000C] py-4 md:px-10 md:py-10'>
        <div className='w-[90%] md:w-[65%] mx-auto'>
          <img src={process.env.PUBLIC_URL+'/images/headet_text.png'} alt="" className='max-w-full' data-aos="fade" data-aos-delay="200" data-aos-duration="1000"/>

        </div>
      </div>

    </div>
  )
}

export default Header